import { capitalize, makeStyles } from '@material-ui/core';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCatalogItem } from '../api/catalog';
import { getVideoOptions, getVideoStats } from '../api/player';
import { DownloadVideoBtn, PlayerInfoDiagrams } from '../components/analysis-player';
import { DropdownBlock } from '../components/DropdownBlock';
import EmotionTable from '../components/EmotionTable';
import { IdentifiedFaces } from '../components/IdentifiedFaces';
import { LinearBarS } from '../components/LinearBar';
import { PageWrapper } from '../components/PageWrapper';
import LineChart from '../components/PulseChart/LineChart';
import { Data } from '../components/PulseChart/types';
import VideoPlayer from '../components/VideoPlayer';
import { fakeFacePng, sincerityHandPng } from '../img';
import { setData, setDataMeanSincerity } from '../store/dataSlice';
import { StoreState } from '../store/types';
import { userSelector } from '../store/userSlice';
import {
  setFpsVideo,
  setVideoSrc,
  setVideoWidth,
  setVideoHeight,
  setWidth,
  setHeight,
} from '../store/videoSlice';
import { mobileMedia } from '../theme';
import getCurrentFrameId from '../utils/getCurrentFrameId';
import {
  convertStats,
  faceWithPreviousFrameEmotionsOnly,
  findFrameData,
} from '../utils/handleStatistics';

const responsiveMedia = '@media(max-width:1115px)';
export { responsiveMedia as analysisPlayerResponsiveMedia };

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 15,
  },
  top: {
    display: 'flex',
    marginBottom: 70,
    paddingBottom: 76,
    marginTop: 48,
    borderBottom: '1px solid #D7DCEF',
    [responsiveMedia]: {
      flexDirection: 'column',
    },
    [mobileMedia]: {
      marginBottom: 40,
      paddingBottom: 40,
    },
  },
  playerSide: {
    flex: '0 1 65%',
    marginRight: 40,
    overflow: 'hidden',
    [responsiveMedia]: {
      marginRight: 0,
    },
  },
  playerWrapper: {
    position: 'relative',
    [responsiveMedia]: {
      height: '75vh',
      minHeight: 250,
      maxHeight: 380,
    },
    [mobileMedia]: {
      maxHeight: 270,
    },
  },
  infoSide: {
    flex: 1,
    [responsiveMedia]: {
      maxWidth: 'none',
    },
  },
  infoTitle: {
    fontSize: 18,
    marginBottom: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  infoItem: {
    display: 'flex',
    '& h4': {
      marginRight: 15,
      fontSize: 16,
      fontWeight: 500,
    },
    '& span': {
      maxWidth: 305,
      display: 'block',
    },
    marginBottom: 30,
  },
  sincerityText: {
    fontSize: 16,
    '& p': {
      marginBottom: 16,
    },
  },

  diagrams: {},
  facesWrapper: {
    marginTop: 25,
    [responsiveMedia]: {
      marginBottom: 32,
    },
  },
  emotionsGraphics: {
    display: 'flex',
    [responsiveMedia]: {
      flexDirection: 'column',
      maxHeight: 'none',
    },
    [mobileMedia]: {
      padding: '0px 16px',
      marginBottom: 40,
    },
    marginBottom: 70,
    justifyContent: 'space-between',
  },
  emotionsSide: {
    flex: '0 1 45%',
    marginRight: 50,
    [responsiveMedia]: {
      flex: '100%',
      paddingBottom: 37,
      borderBottom: '1px solid #CCD6FC',
      marginBottom: 30,
      marginRight: 0,
    },
    '&:last-child': {
      marginRight: 0,
      [mobileMedia]: {
        marginBottom: 0,
      },
    },
  },
  graphicsSide: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
  },

  graphicsWrapper: {
    width: '100%',
    flex: 1,
  },
  loaderWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  chart: {
    overflow: 'hidden',
    [mobileMedia]: {
      padding: '0px 16px',
    },
  },
  tariffName: {
    marginBottom: 50,
    fontSize: 16,
    display: 'block',
  },
}));

export const AnalysisPlayerPage = () => {
  const classes = useStyles();

  const { t } = useTranslation();
  const { id: fileId } = useParams<{ id: string }>();
  const dispatch = useDispatch();

  const user = useSelector(userSelector);
  const [fileInfo, setFileInfo] = useState(null);

  const [dataIsLoading, setDataIsLoading] = useState(true);

  const { data, currentTime, currentFrame, fps } = useSelector((state: StoreState) => ({
    data: state.data.data,
    currentTime: state.video.currentTime,
    currentFrame: state.data.currentFrame,
    fps: state.video.fps,
  }));

  let currentFrameId = getCurrentFrameId(currentTime, currentFrame, fps);

  // from VideoPlayer/index.tsx
  // update data each 2 seconds (not each frame)
  const KEEP_VALUE_SECONDS = 2;
  const KEEP_VALUE_FRAMES = Math.round(KEEP_VALUE_SECONDS * (fps || 30));

  const dataObject: Data = useMemo(() => {
    let d = { ...data.find((e) => e != null && e.frame_id === currentFrameId) };
    if (d) {

      // from VideoPlayer/index.tsx
      // update data each 3 seconds (not each frame)
      const prevSecondFrameId = KEEP_VALUE_FRAMES * Math.floor(d.frame_id / KEEP_VALUE_FRAMES);
      const prevSecondFrameData = findFrameData(data, d, prevSecondFrameId, 10);
      // set prev frame face values ignoring meta data
      d = {
        ...d,
        Objects: d?.Objects?.map((e) => {
          const faceData: any = prevSecondFrameData?.Objects?.find((f) => f.uid === e?.uid);
          if (!!faceData)
            return {
              ...e,
              hr: faceData.hr,
              rr: faceData.rr,
              vi: faceData.vi,
              gazes: faceData.gazes,
              sincerity: faceData.sincerity,
              bp: faceData.bp,
            }
          return e;
        }),
      }

      return {
        ...d,
        Objects: d?.Objects?.map((e) => {
          if (e && e.emotions && (!e.emotions || e.emotions.length === 0))
            return {
              ...e,
              emotions: faceWithPreviousFrameEmotionsOnly(data, d.frame_id, e, fps * 30),
            };
          return e;
        }),
      };
    }

    return d;
  }, [currentFrameId, data, fps]);

  const [currentFaceData, setCurrentFaceData] = useState<any>(null);
  const [currentFaceId, setCurrentFaceId] = useState<number | null>(null);

  useEffect(() => {
    if (
      currentFaceId == null ||
      dataObject == null ||
      !dataObject?.Objects?.find((e: any) => e?.uid === currentFaceId)
    ) {
      setCurrentFaceId(dataObject?.Objects && dataObject?.Objects[0]?.uid);
    }
  }, [currentFaceId, dataObject]);

  useEffect(() => {
    setCurrentFaceData(dataObject?.Objects?.find((e) => e?.uid === currentFaceId));
  }, [dataObject, currentFaceId]);

  useEffect(() => {
    const fetchFileInfo = async () => {
      try {
        setDataIsLoading(true);

        const videoItemInfo = await getCatalogItem(fileId);

        const { data: videoOptions } = await getVideoOptions(fileId);

        const { data } = await getVideoStats(fileId);

        if (data) {
          const stats = convertStats(data?.stats || []);
          dispatch(setData(stats));
          dispatch(setDataMeanSincerity(data?.meansincerity || {}));
        }

        dispatch(setFpsVideo(parseFloat(videoOptions.fps)));
        // scale for face boxes in stats
        const w = parseFloat(videoOptions.width) || 1280;
        const h = parseFloat(videoOptions.height) || 720;
        const scale = h > 720 ? h / 720.0 : 1;
        dispatch(setVideoWidth(w / scale));
        dispatch(setVideoHeight(h / scale));
        dispatch(setWidth(w));
        dispatch(setHeight(h));
        dispatch(setVideoSrc(videoItemInfo.video));
        setFileInfo(videoItemInfo);
      } catch (e) {
      } finally {
        setDataIsLoading(false);
      }
    };

    fetchFileInfo();
  }, [dispatch, fileId]);

  const pulse = currentFaceData?.hr?.replace('NaN', '');

  const curEmotions =
    currentFaceData?.emotions && currentFaceData.emotions !== 0 ? currentFaceData.emotions : [];

  function getStatistic() {
    // Frame - here or not
    // FFF-FF-FF-FF
    const N = fps > 0 ? 10 * fps : 10 * 30;
    if (data) {
      const results: any[] = [];
      const begId = Math.max(0, currentFrameId - N),
        endId = Math.min(currentFrameId, data.length - 1);
      data.forEach((frame) => {
        if (frame != null && frame.frame_id > begId && frame.frame_id < endId) {
          // if previous frame was not found - fill with null
          // if was frame 4 and not 5 - skip
          // if was frame 4 and now 7 - add 2 null
          if (results.length > 0 && results[results.length - 1].frame_id + 1 !== frame.frame_id)
            for (
              let ii: number = results[results.length - 1].frame_id + 1;
              ii < frame.frame_id && results.length < N;
              ++ii
            )
              results.push({ frame_id: ii, Objects: null });
          results.push(frame);
        }
      });
      // if results length < N
      let additional = [];
      for (let i = results.length; i < N; ++i) additional.push(null);
      return additional.concat(results);
    } else return Array(N).fill(null);
  }

  return (
    <PageWrapper showProgress={dataIsLoading}>
      <div className={classes.root}>
        <div className={classes.top}>
          <div className={classes.playerSide}>
            <div className={classes.playerWrapper}>
              <VideoPlayer isLoading={dataIsLoading} isUserManager={user.is_manager} />
            </div>
            <div className={classes.facesWrapper}>
              <IdentifiedFaces
                faces={
                  dataObject?.Objects?.map((d) => ({
                    id: d.uid,
                    image: fakeFacePng,
                  })) || []
                }
                selectedFaceId={currentFaceId}
                setSelectedFaceId={setCurrentFaceId}
              />
            </div>
          </div>
          <div className={classes.infoSide}>
            {user.planName && <span className={classes.tariffName}>{user.planName}</span>}
            <div className={classes.infoTitle}>
              <h3>{capitalize(t('summary'))}</h3>
              <div>
                <DownloadVideoBtn isEnabled={fileInfo?.export} title={user.is_manager ? 'Full' : null} params={user.is_manager ? 'alt' : null} />
                {user.is_manager && (
                  <>
                    <span> </span> {/* space between buttons */}
                    <DownloadVideoBtn isEnabled={fileInfo?.exportbio} title={'HR'} changeColor={true} />
                  </>
                )}
              </div>
            </div>
            <div className={classes.infoItem}>
              <h4>{capitalize(currentFaceData?.sincerity || 0 ? t('sincerity') : t('insincerity'))}</h4>
              <div style={{ flex: 1 }}>
                <LinearBarS
                  value={currentFaceData?.sincerity || 0}
                  height={21}
                  backgroundImage={`url(${sincerityHandPng})`}
                  colorlessFilter={false}
                  thumbStyles={{
                    padding: 6,
                  }}
                />
              </div>
            </div>
            <div className={classes.sincerityText}>
              <p>
                If Sincerity is low,
                the subject scored low on the sincerity index,
                which suggests that throughout the video,
                the subject was likely not being truthful for most of the analyzed period.
              </p>
              <p>
                If Sincerity is not low,
                the subject scored neut on the sincerity index,
                which suggests that throughout the video, the subject's sincerity is inconclusive.
              </p>
            </div>
          </div>
        </div>
        <div className={classes.emotionsGraphics}>
          <DropdownBlock
            title={capitalize(t('physical_display'))}
            wrapperClassName={classes.emotionsSide}
          >
            <PlayerInfoDiagrams currentFaceData={currentFaceData} pulse={pulse} />
          </DropdownBlock>
          <DropdownBlock
            title={capitalize(t('emotional_display'))}
            wrapperClassName={classes.emotionsSide}
          >
            <EmotionTable emotions={curEmotions} />
          </DropdownBlock>
        </div>
        <div className={classes.chart}>
          <DropdownBlock
            title={capitalize(t('photoplethysmogram'))}
            wrapperClassName={classes.graphicsSide}
          >
            <div className={classes.graphicsWrapper}>
              <LineChart dataSets={getStatistic()} color="red" id={currentFaceData?.uid} />
            </div>
          </DropdownBlock>
        </div>
      </div>
    </PageWrapper>
  );
};
