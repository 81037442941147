import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { getMe } from './api/auth';
import CustomToaster from './components/CustomToaster';
import {
  AnalysisPage,
  AnalysisPlayerPage,
  CatalogPage,
  PrivacyPolicyPage,
  ReportPage,
  TermsAndConditionsPage,
} from './pages';
import { LoginPage } from './pages/login';
import { ScrollToTop } from './router';
import { setUserState } from './store/userSlice';

const App = () => {
  const { i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchInitialUserData = async () => {
      try {
        setIsLoading(true);
        const { data: userData } = await getMe();

        if (userData?.lang !== 'en') {
          await i18n.changeLanguage('en');
        }

        if (userData) {
          dispatch(
            setUserState({
              firstName: userData?.first_name || '',
              planName: userData?.plan_name || '-',
              lastName: userData?.last_name || '',
              uploadsRemaining: userData?.plan_month_max || 0,
              planDurationMaxSec: userData?.plan_duration_max || 0,
              is_manager: userData?.is_manager,
            })
          );
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };

    fetchInitialUserData();
  }, [dispatch, i18n]);

  if (isLoading) return null;

  return (
    <>
      <CustomToaster />
      <ScrollToTop />
      <Switch>
        <Route exact path="/analysis" component={AnalysisPage} />
        <Route path="/analysis/:id" component={AnalysisPlayerPage} />
        <Route path="/catalog" component={CatalogPage} />
        <Route path="/login" component={LoginPage} />
        <Route path="/report/:id" component={ReportPage} />
        <Route path="/privacy-policy" component={PrivacyPolicyPage} />
        <Route path="/terms-and-conditions" component={TermsAndConditionsPage} />
        <Route path="/" component={() => <Redirect to="/catalog" />} />
      </Switch>
    </>
  );
};

export default App;
