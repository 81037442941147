import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VideoState } from './types';

const initialState: VideoState = {
  src: '',
  fps: 30,
  currentTime: 0.0,
  pause: false,
  height: 0,
  width: 0,
  videoHeight: 360,
  videoWidth: 640,
  duration: -1,
};

export const videoSlice = createSlice({
  name: 'video',
  initialState,
  reducers: {
    setVideoSrc(state: VideoState, action: PayloadAction<string>) {
      state.src = `${process.env.REACT_APP_BASE_URL}${action.payload.replace(/"/g, '')}`;
    },
    setFpsVideo(state: VideoState, action: PayloadAction<number>) {
      state.fps = action.payload;
    },
    setCurrentTime(state: VideoState, action: PayloadAction<number>) {
      state.currentTime = action.payload;
    },
    setPause(state: VideoState, action: PayloadAction<boolean>) {
      state.pause = action.payload;
    },
    setHeight(state: VideoState, action: PayloadAction<number>) {
      state.height = action.payload;
    },
    setWidth(state: VideoState, action: PayloadAction<number>) {
      state.width = action.payload;
    },
    setVideoHeight(state: VideoState, action: PayloadAction<number>) {
      state.videoHeight = action.payload;
    },
    setVideoWidth(state: VideoState, action: PayloadAction<number>) {
      state.videoWidth = action.payload;
    },
    setVideoDuration(state: VideoState, action: PayloadAction<number>) {
      state.duration = action.payload;
    },
  },
});

export const {
  setVideoSrc,
  setFpsVideo,
  setCurrentTime,
  setPause,
  setHeight,
  setWidth,
  setVideoWidth,
  setVideoHeight,
  setVideoDuration,
} = videoSlice.actions;
export default videoSlice.reducer;
