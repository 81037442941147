import { AxiosRequestConfig } from 'axios';
import { api } from '.';

export const uploadFile = ({
  file,
  onProgressChange,
  config = {},
}: {
  file: File;
  onProgressChange?: (progress: number) => any;
  config?: AxiosRequestConfig;
}) => {
  const data = new FormData();
  data.append('file', file);

  return api.post('/upload/file', data, {
    onUploadProgress(progressEvent) {
      const progress = Math.ceil((progressEvent.loaded / progressEvent.total) * 100);
      onProgressChange(progress);
    },
    ...config,
  });
};

export const uploadLink = (link: string) => {
  return api.post('/upload/link', {
    link,
  });
};
