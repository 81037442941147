import { Data, ObjectData } from '../components/PulseChart/types';

// framesCountToSearch - use -1 to search in all values or use fps*30seconds
export const faceWithPreviousFrameEmotionsOnly = (
  statistics: Data[],
  actualFrameId: number,
  actualFace: ObjectData,
  framesCountToSearch: number,
) => {
  // rename
  const data = statistics;
  // get data from previous frame
  const maxFramesCountToSearch = Math.max(actualFrameId - framesCountToSearch, 0);
  for (let pfd = data.length - 1; pfd >= maxFramesCountToSearch; --pfd) {
    const previousFrameData = data[pfd];
    // skip not actual frames
    if (previousFrameData.frame_id >= actualFrameId) continue;
    // stop search when some frames are not in array
    if (previousFrameData.frame_id < maxFramesCountToSearch) break;
    const previousFaces = previousFrameData.Objects;
    // get face data with current face id
    const previousFace =
      previousFaces == null ? null : previousFaces.find((f) => f.uid === actualFace?.uid);

    if (previousFace != null && previousFace.emotions != null && previousFace.emotions.length > 0) {
      // found for this face - return emotions
      return previousFace.emotions;
    }
  }
  return actualFace?.emotions;
};

export const faceWithPreviousFrameEmotionsOnlyPts = (
  statistics: Data[],
  actualFrameId: number,
  actualFace: ObjectData,
  minTimeSecondsToSearch: number,
  ScaleSecPts: number,
) => {
  const minTimeSecondsToSearchPts = minTimeSecondsToSearch * ScaleSecPts;

  // statistics.reverse().find((e) => e && (e.pts <= timeSecPts) && (timeSecPts - e.pts) < ActualSecPts);

  // rename
  const data = statistics;
  // get data from previous frame
  // const maxFramesCountToSearch = Math.max(actualFrameId - framesCountToSearch, 0);
  for (let pfd = data.length - 1; pfd >= 0; --pfd) {
    const previousFrameData = data[pfd];
    // skip not actual frames
    if (previousFrameData.frame_id >= actualFrameId) continue;
    // stop search when some frames are not in array
    if (previousFrameData.pts < minTimeSecondsToSearchPts) break;
    const previousFaces = previousFrameData.Objects;
    // get face data with current face id
    const previousFace =
      previousFaces == null ? null : previousFaces.find((f) => f.uid === actualFace?.uid);

    if (previousFace?.emotions?.length > 0) {
      // found for this face - return emotions
      return previousFace.emotions;
    }
  }
  return actualFace?.emotions;
};

export function getMaxFromEmotions(emotions: any[]) {
  if (!emotions || emotions.length === 0)
    return null;
  let maxElIndex = 0;
  let maxElPercent = emotions[maxElIndex][1];
  for (let i = maxElIndex + 1; i < emotions.length; ++i) {
    if (maxElPercent < emotions[i][1]) {
      maxElPercent = emotions[i][1];
      maxElIndex = i;
    }
  }
  return emotions[maxElIndex];
}

// remove not clustered faces
// convert hr in statistics
export function convertStats(statistics: Data[]) : Data[] {
  return statistics.map((e) => {
    const objects: ObjectData[] = e.Objects;
    // remove not clustered faces
    const objectsA = objects.filter((od) => (typeof od.vi === 'number') && od.vi >= 0);
    // convert hr inside objects
    const objectsB = objectsA.map((od) => {
      const newVal = (typeof od.hr === 'number') || !(od?.hr?.includes('%') && od?.hr !== 'N/A')
        ? `${parseInt(`${od.hr}`) + 60}`
        : od.hr;
      return {...od, hr: newVal};
    });
    return {...e, Objects: objectsB};
  });
}

export const findFrameData = (
  statistics: Data[],
  actualFrame: Data,
  fromFrameId: number,
  windowsFromFrameId: number,
) => {
  const MaxSearchFrameId = Math.min(fromFrameId + windowsFromFrameId, actualFrame.frame_id);
  for (let pfd = 0; pfd < statistics.length; ++pfd) {
  // for (let pfd = data.length - 1; pfd >= maxFramesCountToSearch; --pfd) {
    const frameData = statistics[pfd];
    // skip not actual frames
    if (frameData.frame_id < fromFrameId) continue;
    // find first target frame
    if (fromFrameId <= frameData.frame_id && frameData.frame_id < MaxSearchFrameId)
      return frameData;
    // finish search
    if (frameData.frame_id > MaxSearchFrameId) break;
  }
  return actualFrame;
};
