import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import FaceBox from './FaceBox';
import Modal from './Modal';
import { StoreState } from '../../store/types';

const useStyles = makeStyles<Theme, FakeVideoItemProps>({
  root: {
    position: 'absolute',
    left: (props) => (100 * props.positions[0]) / props.videoWidth + '%',
    top: (props) => (100 * props.positions[1]) / props.videoHeight + '%',
    width: (props) => (100 * props.positions[2]) / props.videoWidth + '%',
    height: (props) => (100 * props.positions[3]) / props.videoHeight + '%',
    zIndex: 1,
    pointerEvents: 'none',
  },
  rootStatic: {
    position: 'absolute',
    left: '1%',
    // compute top based on sum of heights of previous elements
    top: (props) => `${props.drawInListFaceBoxes
      ?.slice(0, props.drawInListIndex - 1) // elements before
      .map((e:any) => (e && e[3] || 10)) // get height
      .reduce((a:any, b:any) => a + b, 0) // sum
      * 100 / props.videoHeight // to percents
    }%`,
    width: (props) => (100 * props.positions[2]) / props.videoWidth + '%',
    height: (props) => (100 * props.positions[3]) / props.videoHeight + '%',
    margin: (props) => `1%`,
    zIndex: (props) => 10 - props.drawInListIndex,
    pointerEvents: 'none',
  },
  rootStats: {
    position: 'relative',
    width: 'fit-content',
  },
  wrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
    padding: '8px 12px',
  },
  bg: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundColor: '#161A1C',
    opacity: 0.6,
  },
});

interface VideoItemProps {
  emotions: any[];
  positions: number[];
  isPositionsNotScaled: boolean;
  colorRgb: number[] | string;
  hr: number | string;
  rr: number | string;
  bp: number | string;
  eyes: string;
  sincerity: number | string;
  showParamsAll: boolean;
  drawInListIndex: number;
  drawInListFaceBoxes: any;
  itemType: string;
}

interface FakeVideoItemProps {
  emotions: any[];
  positions: number[];
  isPositionsNotScaled: boolean;
  colorRgb: number[] | string;
  videoWidth: number;
  videoHeight: number;
  drawInListIndex: number;
  drawInListFaceBoxes: any;
}

const VideoItem: React.FC<VideoItemProps> = ({
  emotions,
  positions,
  isPositionsNotScaled,
  colorRgb,
  hr,
  eyes,
  rr,
  bp,
  sincerity,
  showParamsAll,
  drawInListIndex,
  drawInListFaceBoxes,
  itemType,
}) => {
  const { videoWidth, videoHeight, width, height } = useSelector((state: StoreState) => ({
    videoWidth: state.video.videoWidth,
    videoHeight: state.video.videoHeight,
    width: state.video.width,
    height: state.video.height,
  }));
  const videoWidthHere = isPositionsNotScaled ? width : videoWidth;
  const videoHeightHere = isPositionsNotScaled ? height : videoHeight;

  const classes = useStyles({
    emotions,
    positions,
    isPositionsNotScaled,
    colorRgb,
    videoWidth: videoWidthHere,
    videoHeight: videoHeightHere,
    drawInListIndex,
    drawInListFaceBoxes,
  });

  // manually change z index on hover to show modal div
  const [zIndexHere, setZIndexHere] = React.useState(4);

  function setIsShown(isShown: boolean) {
    if (isShown && zIndexHere !== 5) setZIndexHere(5);
    if (!isShown && zIndexHere !== 4) setZIndexHere(4);
  }

  function getModalPositions(): any[] {
    const fbLeft = (100 * positions[0]) / videoWidthHere;
    const fbWidth = (100 * positions[2]) / videoWidthHere;
    let modalLeft = '102%';
    let modalTop = '5%';
    let arrowType = 'right';
    let translateX = '0%';
    let translateY = '0%';

    // X
    if (fbLeft + fbWidth < 50) {
    } else if (fbLeft > 50) {
      modalLeft = '-2%'; // move left
      translateX = '-100%';
      arrowType = 'left';
    } else {
      // compare available spaces
      if (fbLeft > 100 - (fbLeft + fbWidth)) {
        modalLeft = '-2%'; // move left
        translateX = '-100%';
        arrowType = 'left';
      }
    }

    return [modalLeft, modalTop, translateX, translateY, arrowType];
  }

  let color_rgb:any = colorRgb;
  if (!!drawInListIndex && Array.isArray(colorRgb)) {
    if (color_rgb[0] <= 1 && color_rgb[1] <= 1 && color_rgb[2] <= 1) color_rgb = [255 * color_rgb[0], 255 * color_rgb[1], 255 * color_rgb[2]];
    color_rgb = `rgb(${color_rgb[0]},${color_rgb[1]},${color_rgb[2]})`;
  }

  if (itemType === 'boxonly') {
    return (
      <div
        className={classes.root}
        style={{ zIndex: zIndexHere }}
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
      >
        <FaceBox color={`${color_rgb || "#f92974"}`} />
      </div>
    );
  }

  const modalPositions = getModalPositions();
  // use static position
  if (!!drawInListIndex)
    modalPositions[2] = modalPositions[3] = '0%';

  if (emotions && itemType === 'statsonly') {
    return (
      <div
        className={classes.rootStats}
        style={{ zIndex: zIndexHere }}
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
      >
        <Modal
          rr={rr}
          eyes={eyes}
          hr={hr}
          bp={bp}
          sincerity={sincerity}
          position={modalPositions[4]}
          positions={modalPositions}
          emotions={emotions}
          showParamsAll={showParamsAll}
          drawInListIndex={drawInListIndex}
          drawInListColorRgb={drawInListFaceBoxes?.length > 1 ? color_rgb : null}
          itemType={itemType}
        />
      </div>
    );
  }

  if (emotions) {
    return (
      <>
      <div
        className={classes.root}
        style={{ zIndex: zIndexHere }}
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
      >
        <FaceBox color={`${color_rgb || "#f92974"}`} />
      </div>
      <div
        className={drawInListIndex ? classes.rootStatic : classes.root}
        style={{ zIndex: zIndexHere, borderTopColor: 'red' }}
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}>
        <Modal
          rr={rr}
          eyes={eyes}
          hr={hr}
          bp={bp}
          sincerity={sincerity}
          position={modalPositions[4]}
          positions={modalPositions}
          emotions={emotions}
          showParamsAll={showParamsAll}
          drawInListIndex={drawInListIndex}
          drawInListColorRgb={drawInListFaceBoxes?.length > 1 ? color_rgb : null}
          itemType={null}
        />
      </div>
      </>
    );
  }

  return null;
};

export default VideoItem;
