import { capitalize, CircularProgress, makeStyles } from '@material-ui/core';
import cn from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { API_BASE_URL, getAuthToken } from '../api';
import { getCatalogList } from '../api/catalog';
import { getMe, getMeOnly, getMeUpdate, getMeUpdated } from '../api/auth';
import {
  CatalogHeader,
  CatalogHint,
  CatalogUserPlan,
  CatalogVideoList,
} from '../components/catalog';
import { PageWrapper } from '../components/PageWrapper';
import { userSelector, setUserState } from '../store/userSlice';
import { mobileMedia } from '../theme';
import { CatalogItem } from '../types/catalog';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(() => ({
  root: {
    margin: '0 auto',
    marginTop: 24,
    width: '100%',
    display: 'flex',
    '@media(max-width:620px)': {
      flexDirection: 'column',
    },
  },
  newAnalysis: {
    display: 'block',
    margin: '0 auto',
  },
  loadMoreBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    width: 200,
    height: 50,
    maxWidth: '100%',
    '@media(max-width:620px)': {
      padding: '13px 35px',
      width: 'auto',
      marginRight: 34,
      marginBottom: 20,
    },
  },
  infoSide: {
    flex: 1,
    marginRight: 154,
    [mobileMedia]: {
      marginRight: 100,
    },
    '@media(max-width:620px)': {
      marginRight: 0,
    },
  },
  videoList: {
    flex: '0 0 260px',
    maxWidth: 260,
    [mobileMedia]: {
      flex: '0 0 170px',
      maxWidth: 170,
    },
    '@media(max-width:620px)': {
      maxWidth: '100%',
      flex: 1,
    },
  },
  desktopHint: {
    '@media(max-width:620px)': {
      display: 'none',
    },
  },
  mobileHint: {
    display: 'none',
    '@media(max-width:620px)': {
      display: 'block',
    },
  },
  uploadNewVideoBtnDesktop: {
    marginBottom: 50,
    [mobileMedia]: {
      marginBottom: 41,
    },
    '@media(max-width:620px)': {
      display: 'none',
    },
  },
  controlButtons: {
    marginTop: 20,
    marginBottom: 20,
    '@media(max-width:620px)': {
      marginTop: 50,
      marginBottom: 30,
      display: 'flex',
      flexWrap: 'wrap',
    },
  },
  uploadNewVideoBtnMobile: {
    display: 'none',
    '@media(max-width:620px)': {
      display: 'block',
      '& button': {
        display: 'inline',
      },
    },
  },
}));

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

function convertDateFromUTCToPrint(utcSeconds: number) {
  const d = new Date(0);
  d.setUTCSeconds(utcSeconds);
  return `${monthNames[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
}

const STEP = 4;

export const CatalogPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [catalogItems, setCatalogItems] = useState<CatalogItem[]>([]);
  const [fetchAllItems, setFetchAllItems] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [isInitialDataLoaded, setIsInitialDataLoaded] = useState(false);
  const timerLoadList = useRef(null);
  const user = useSelector(userSelector);
  const plansUpdatedAtRef = useRef(null);

  const [statsCounter, setStatsCounter] = useState({
    total: 0,
    stored: 0,
    inProgress: 0,
    gleens: 0, // plan month max
    remaining: 0, // current month available
  });

  const onLoadMore = () => {
    setFetchAllItems(true);
    setHasMore(false);
  };

  const { t } = useTranslation();

  const onNewAnalysisClick = () => history.push('/analysis');

  // send requests about update user data each 5 seconds
  // if it is updated then load new data updating 'uploadsRemaining' to call 'fetchCatalogList'
  useEffect(() => {
    const fetchUpdateUserData = async () => {
      try {
        // load user data
        const { data } = await getMeOnly();
        if (data) {
          dispatch(
            setUserState({
              firstName: data?.first_name || '',
              planName: data?.plan_name || '-',
              lastName: data?.last_name || '',
              uploadsRemaining: data?.plan_month_max || 0,
              planDurationMaxSec: data?.plan_duration_max || 0,
              is_manager: data?.is_manager,
            })
          );
        }
      } catch (error) {}
    };

    let timerId: number | null = window.setInterval(async () => {
      try {
        const { data } = await getMeUpdated();
        if (data && data.plans_updated_at && plansUpdatedAtRef.current !== data.plans_updated_at) {
            plansUpdatedAtRef.current = data.plans_updated_at;
            await fetchUpdateUserData();
        }
      } catch (error) {
      }
    }, 5000);

    return () => {
      clearInterval(timerId);
    };
  }, [dispatch]);

  useEffect(() => {
    const fetchUpdateUserData = async () => {
      try {
        // update user data on server
        getMeUpdate();
        // load user data
        const { data } = await getMe();
        if (data) {
          dispatch(
            setUserState({
              firstName: data?.first_name || '',
              planName: data?.plan_name || '-',
              lastName: data?.last_name || '',
              uploadsRemaining: data?.plan_month_max || 0,
              planDurationMaxSec: data?.plan_duration_max || 0,
              is_manager: data?.is_manager,
            })
          );
        }
      } catch (error) {}
    };
    fetchUpdateUserData();
  }, [dispatch]);

  const fetchCatalogList = useCallback(
    async ({ isRefetchFullData = false }) => {
      try {
        if (!isRefetchFullData) {
          setIsLoading(true);
        }

        const { data } = await getCatalogList(
          fetchAllItems
            ? {}
            : {
                beg: 0,
                end: STEP,
              }
        );

        const fetchedItems = data?.list;

        if (!Array.isArray(fetchedItems)) {
          return;
        }

        setCatalogItems((prev) => {
          const prevItems = isRefetchFullData || fetchAllItems ? [] : prev;
          const t = getAuthToken();

          return [
            ...prevItems,
            ...data.list.map((c: any) => ({
              fileId: c.fileid,
              preview: c.preview && t && API_BASE_URL + c.preview + `?t=${t}`,
              date: convertDateFromUTCToPrint(c.created_at),
              withReport: c.report,
              withPlayer: c.stat,
              withExport: c.export,
              withExportBio: c.exportbio,
            })),
          ];
        });

        setStatsCounter({
          inProgress: data.progress || 0,
          total: data.total || 0,
          stored: data.stats || 0, // count of files with videos
          gleens: data.plan_month_max || 0,
          remaining: data.month_remaining || 0,
        });

        if (data.total <= STEP) {
          setHasMore(false);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
        setIsInitialDataLoaded(true);
      }
    },
    [fetchAllItems]
  );

  useEffect(() => {
    if (user.planName) {
      fetchCatalogList({ isRefetchFullData: false });
    }
  }, [fetchCatalogList, user.planName, user.uploadsRemaining, user.planDurationMaxSec]);

  useEffect(() => {
    if (statsCounter.inProgress > 0) {
      // restart interval to handle changed values (beg end) or create new
      if (timerLoadList.current) {
        // restart interval
        clearInterval(timerLoadList.current);
        timerLoadList.current = setInterval(
          () => fetchCatalogList({ isRefetchFullData: true }),
          3000
        );
      } else {
        timerLoadList.current = setInterval(
          () => fetchCatalogList({ isRefetchFullData: true }),
          3000
        );
      }
    } else {
      if (timerLoadList.current) {
        clearInterval(timerLoadList.current);
        timerLoadList.current = null;
      }
    }

    return () => {
      if (timerLoadList.current) {
        clearInterval(timerLoadList.current);
      }
    };
  }, [statsCounter.inProgress, fetchCatalogList]);

  return (
    <PageWrapper showProgress={!isInitialDataLoaded}>
      <div className={classes.root}>
        <div className={classes.infoSide}>
          <CatalogUserPlan
            planName={user.planName}
            uploadsLeft={statsCounter.remaining}
            username={`${user.firstName} ${user.lastName}`}
          />
          <CatalogHeader
            total={statsCounter.gleens}
            remaining={statsCounter.remaining}
            stored={statsCounter.stored}
          />
          <div className={classes.uploadNewVideoBtnDesktop}>
            <button onClick={onNewAnalysisClick} className={'primary-btn ' + classes.newAnalysis}>
              {capitalize(t('upload_new_video'))}
            </button>
          </div>
          <div className={classes.desktopHint}>
            <CatalogHint />
          </div>
        </div>
        <div className={classes.videoList}>
          <CatalogVideoList
            items={catalogItems}
            removeItemById={(fileId) =>
              setCatalogItems((prev) => prev.filter((i) => i.fileId !== fileId))
            }
            isUserManager={user.is_manager}
          />
          <div className={classes.controlButtons}>
            {hasMore && catalogItems.length > 0 && (
              <button onClick={onLoadMore} className={cn('secondary-btn', classes.loadMoreBtn)}>
                {isLoading ? <CircularProgress size={16} /> : 'View all'}
              </button>
            )}
            <div className={classes.uploadNewVideoBtnMobile}>
              <button onClick={onNewAnalysisClick} className={'primary-btn ' + classes.newAnalysis}>
                {capitalize(t('upload_new_video'))}
              </button>
            </div>
          </div>
        </div>
        <div className={classes.mobileHint}>
          <CatalogHint />
        </div>
      </div>
    </PageWrapper>
  );
};
